<template>
  <div class="flex flex-col font-Inter mb-3">
    <!-- Contenu  -->
    <PageHeader>
      <div class="text-gray-900 text-2xl font-semibold">
        Gestion des clients
      </div>
    </PageHeader>

    <div class="flex pt-6 px-6">
      <div class="bg-white shadow w-full px-6 pt-5">
        <div class="flex flex-row justify-between mb-5">
          <el-button
            class="mr-2"
            type="primary"
            icon="el-icon-plus"
            @click="handleCreate"
            >créer un client</el-button
          >
          <el-input
            class="mr-2"
            placeholder="Rechercher par nom, telephone et email "
            v-model="listQuery.search"
            @keydown.native.enter="getList()"
            @input.native="searchCustomers()"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>

          <el-button class="mr-2">Rechercher</el-button>

          <el-dropdown @command="handleExport">
            <el-button :loading="exportLoading">
              Exporter <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="PDF-TOTAUX">EN PDF</el-dropdown-item>
              <el-dropdown-item command="EXCEL">EN EXCEL</el-dropdown-item>
              <el-dropdown-item command="EXCEL-ALL"
                >EN EXCEL AU COMPLET</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="customer-white">
          <div class="mb-2">Nombre des clients : {{ listQuery.total }}</div>
          <skeleton-list :row="6" v-if="listLoading" class="mb-6" />
          <el-table
            v-else
            :data="items"
            style="width: 100%"
            v-loading="listLoading"
            @row-click="handleFiche"
            :show-header="true"
          >
            <el-table-column label="" width="80">
              <template slot-scope="{ row }">
                <el-switch
                  size="medium"
                  v-model="row.active"
                  @change="handleActive(row.contact_no)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="N° CLIENT" width="170">
              <template slot-scope="{ row }">
                <router-link :to="'fiche/' + row.contact_id">
                  <div class="text-blue">{{ row.contact_no }}</div>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="Contact"
              label="NOM DU CLIENT"
              min-width="200"
            >
              <template slot-scope="{ row }">
                <router-link :to="'fiche/' + row.contact_id">
                  <div class="uppercase">
                    {{ row.contact_name }}
                  </div>
                </router-link>
              </template>
            </el-table-column>

            <el-table-column prop="phone" label="TÉLÉPHONE" width="200">
              <template slot-scope="{ row }">
                <span>{{ row.code_phone }}{{ row.phone }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="street" label="ADRESSE">
              <template slot-scope="{ row }">
                <span>{{ row.street }}</span>
              </template>
            </el-table-column>
            <el-table-column label="TOTAL À PAYER" width="170" align="right">
              <template slot-scope="{ row }">
                <div v-if="row.account_balance < 0">
                  <span>
                    {{
                      (row.total_invoice + Math.abs(row.account_balance))
                        | moneyFilter
                    }}
                    {{user.currency_code}}
                  </span>
                </div>

                <div v-else>{{ row.total_invoice | moneyFilter }} {{user.currency_code}}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="TOTAL À RECEVOIR "
              width="170"
              align="right"
            >
              <template slot-scope="{ row }">
                <div>
                  <span>
                    {{
                      (row.total_credit + Math.abs(row.account_credit_balance))
                        | moneyFilter
                    }}
                    {{user.currency_code}}
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="ACTIONS" align="right" width="100">
              <template slot-scope="{ row }">
                <el-dropdown @command="handleCommand" trigger="click">
                  <el-button size="mini" icon="el-icon-menu" circle></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{ update: row }">
                      <i class="el-icon-edit"></i>
                      Modifier</el-dropdown-item
                    >
                    <el-dropdown-item :command="{ info: row }">
                      <i class="el-icon-document"></i>
                      Afficher</el-dropdown-item
                    >
                    <!--   <el-dropdown-item command="quote" 
                      >Créer un devis</el-dropdown-item
                    >
                    <el-dropdown-item command="invoice"
                      >Créer une facture</el-dropdown-item
                    >
                    <el-dropdown-item command="order"
                      >Créer une commande</el-dropdown-item
                    >

                    <el-dropdown-item command="payment"
                      >Créer un paiement</el-dropdown-item
                    >-->
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex justify-end">
          <pagination
            v-show="listQuery.total > 0"
            :total="listQuery.total"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.size"
            @pagination="getList()"
          />
        </div>
      </div>
    </div>

    <el-dialog
      top="62px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
    >
      <div slot="title">
        <h2 class="text-2xl" v-if="dialogStatus === 'create'">
          Enregistrer un client
        </h2>
        <h2 class="text-2xl" v-if="dialogStatus === 'update'">
          Modifier un client
        </h2>
      </div>
      <el-form class="form" :rules="rules" ref="contactForm" :model="contact">
        <el-form-item label="Nom complet du client" prop="contact_name">
          <el-input class="w-full" type="text" v-model="contact.contact_name" />
        </el-form-item>

        <div class="flex">
          <el-form-item label="Téléphone" class="w-1/2 mr-4" prop="phone">
            <el-input
              class="w-full input-with-select"
              type="phone"
              v-model="contact.phone"
            >
              <el-select
                v-model="contact.code_phone"
                slot="prepend"
                placeholder="code"
              >
                <el-option
                  v-for="(item, index) in countries"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-input>
          </el-form-item>

          <el-form-item label="Entreprise" class="w-1/2">
            <el-input class="w-full" type="text" v-model="contact.entreprise" />
          </el-form-item>
        </div>

        <div class="flex flex-row">
          <el-form-item label="Email" class="w-1/2 mr-4" prop="email">
            <el-input class="w-full" type="email" v-model="contact.email" />
          </el-form-item>
          <el-form-item label="Téléphone whatsapp" class="w-1/2">
            <el-input
              class="w-full input-with-select"
              type="phone"
              v-model="contact.phone_whatsapp"
            >
              <el-select
                v-model="contact.codephone_whatsapp"
                slot="prepend"
                placeholder="code"
              >
                <el-option
                  v-for="(item, index) in countries"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </div>
        <div class="flex my-2">
          <el-switch size="medium" v-model="contact.add_account"> </el-switch>
          <span class="ml-2">créer une compte créditeur pour le client </span>
        </div>

        <el-form-item label="Adresse de facturation" prop="street">
          <el-input
            type="textarea"
            placeholder="rue,commune, ville, "
            v-model="contact.street"
            :autosize="{ minRows: 4, maxRows: 4 }"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="Adresse" prop="street">
          <el-input class="w-full" type="phone" v-model="organization.street" />
        </el-form-item> -->

        <div class="flex flex-row justify-end">
          <el-button @click="handleCancel" type="">Annuler</el-button>
          <el-button
            type="primary"
            :loading="loading"
            :disabled="loading"
            @click="
              dialogStatus === 'create'
                ? createData('contactForm')
                : updateData('contactForm')
            "
          >
            Enregistrer
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  addContact,
  fetchCustomers,
  updateContact,
  activeContact,
} from "@/api/contact";
import {
  exportCustomersList,
  exportCustomersAmountList,
  exportExcelCustomers,
  exportExcelAllCustomers,
} from "@/api/export";
import Pagination from "@/components/Pagination";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import PageHeader from "@/components/PageHeader";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";
export default {
  name: "Contact",
  components: { Pagination, PageHeader, SkeletonList },
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      stats: {
        total: "2",
        total_customer: 2,
        total_partener: 0,
        total_supplier: 0,
      },
      countries: ["221", "223", "224", "225"],
      items: [],
      loadingList: false,
      step: "CUSTOMER",
      file: undefined,
      listLoading: false,
      exportLoading: false,
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        loading: false,
        sort: "asc",
        sortDir: "contactName",
        search: undefined,
        phone: undefined,
        email: undefined,
        typeFile: undefined,
      },

      dialogStatus: "create",
      dialogFormVisible: false,
      loading: false,

      contact: {
        contact_id: undefined,
        contact_no: undefined,
        active: undefined,
        type_contact: "CUSTOMER",
        contact_name: "",
        entreprise: "",
        first_name: "",
        last_name: "",
        phone: "",
        phone_whatsapp: "",
        is_business: false,
        street: "",
        code_phone: "221",
        codephone_whatsapp: "221",
        city: "",
        add_account: true,
      },
      rules: {
        phone: [
          {
            required: true,
            message: "Le téléphone est obligatoire",
            trigger: "blur",
          },
        ],

        contact_name: [
          {
            required: true,
            message: "Dénomination est obligatoire",
            trigger: "blur",
          },
        ],
        street: [
          {
            required: true,
            message: "L'adresse est obligatoire",
            trigger: "blur",
          },
        ],
      },

      dialogTitle: "Ajouter un contact",
    };
  },
    computed :{
        ...mapGetters(["user"]),
    },
  mounted() {
    // this.getStats();
    this.getList();

    const params = this.$route.params;

    if (params.newcustomer) {
      console.log(params.newcustomer);
      this.dialogFormVisible = true;
    }
  },

  methods: {
    // la liste
    async getList() {
      this.listLoading = true;
      if (this.listQuery.search === "") {
        this.listQuery.search = undefined;
      }
      await fetchCustomers(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleCreate() {
      this.resetContact();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.loading = false;
      this.$nextTick(() => {
        this.$refs["contactForm"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.contact = Object.assign({}, row); // copy obj
      this.dialogStatus = "update";
      this.loading = false;
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["contactForm"].clearValidate();
      });
    },
    updateData(contactForm) {
      this.$refs[contactForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.contact.type_contact = "CUSTOMER";

          updateContact(this.contact, this.contact.contact_no)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                const index = this.items.findIndex(
                  (v) => v.contact_id === this.contact.contact_id
                );
                this.contact.contact_name =
                  this.contact.contact_name.toUpperCase();
                this.items.splice(index, 1, this.contact);
                this.dialogFormVisible = false;
                this.$message({
                  title: "Success",
                  message: "Modifier avec succès",
                  type: "success",
                  duration: 2000,
                });
              }, 3000);
            })
            .catch((error) => {
              setTimeout(() => {
                if (error.status === 409) {
                  this.$message({
                    title: "Erreur",
                    message: error.data.message,
                    type: "error",
                    duration: 2000,
                  });
                }

                if (error.status === 400) {
                  this.$message({
                    title: "Erreur",
                    message: "Veuillez remplir les champs obligatoire",
                    type: "error",
                    duration: 2000,
                  });
                }
                this.loading = false;
              }, 1.5 * 1000);
            });
        }
      });
    },

    resetContact() {
      this.contact = {
        contact_id: undefined,
        contact_no: undefined,
        type_contact: "CUSTOMER",
        contact_name: "",
        first_name: "",
        last_name: "",
        phone: "",
        is_business: false,
        code_phone: "221",
        codephone_whatsapp: "221",
        street: "",
        city: "",
        add_account: true,
      };
    },

    createData(contactForm) {
      this.$refs[contactForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          addContact(this.contact)
            .then((res) => {
              setTimeout(() => {
                this.loading = false;

                this.contact.contact_id = res.data.contact_id;
                this.contact.contact_no = res.data.contact_no;
                this.contact.active = true;
                this.contact.contact_name =
                  this.contact.contact_name.toUpperCase();

                this.items.unshift(this.contact);
                this.dialogFormVisible = false;
                this.$message({
                  title: "Success",
                  message: "La client ajouter avec succès",
                  type: "success",
                  duration: 2000,
                });
              }, 3000);
            })
            .catch((error) => {
              setTimeout(() => {
                if (error.status === 409) {
                  this.$message({
                    title: "Erreur",
                    message: error.data.message,
                    type: "error",
                    duration: 2000,
                  });
                }

                if (error.status === 400) {
                  this.$message({
                    title: "Erreur",
                    message: "Veuillez remplir les champs obligatoire",
                    type: "error",
                    duration: 2000,
                  });
                }
                this.loading = false;
              }, 1.5 * 1000);
            });
        }
      });
    },

    exportCustomerList() {
      this.exportLoading = true;
      exportCustomersList(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "clients" + this.file); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
          console.log("hello");
        });
    },
    exportExcelCustomer() {
      this.exportLoading = true;
      exportExcelCustomers(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "clients.xls"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
          console.log("hello");
        });
    },
    exportExcelAllCustomer() {
      this.exportLoading = true;
      exportExcelAllCustomers()
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "clients.xls"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exportCustomerAmount() {
      this.exportLoading = true;
      exportCustomersAmountList(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "clients" + this.file); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
          console.log("hello");
        });
    },
    handleExport(e) {
      switch (e) {
        case "PDF":
          this.listQuery.typeFile = "PDF";
          this.file = ".pdf";
          this.exportCustomerList();
          break;
        case "PDF-TOTAUX":
          this.listQuery.typeFile = "PDF";
          this.file = ".pdf";
          this.exportCustomerAmount();
          break;
        case "EXCEL":
          this.exportExcelCustomer();
          break;
        case "EXCEL-ALL":
          this.exportExcelAllCustomer();
          break;
      }
    },
    handleCancel() {
      this.dialogFormVisible = false;
      this.resetContact();
    },
    async handleActive(numero) {
      await activeContact(numero)
        .then(() => {
          this.$message({
            message: "Le statut du client a changé !",
            type: "success",
          });
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleFiche(e) {
      console.log(e);
    },
    handleCommand(e) {
      if (e.update) {
        this.handleUpdate(e.update);
      }
      if (e.info) {
        this.$router.push(`fiche/${e.info.contact_id}`);
      }
    },
    searchCustomers() {
      if (this.listQuery.search.length > 2) {
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
